import React, { useEffect, useState } from "react";
import logo from "../images/logo-01.png";
import rubiesImage from "../images/many-rubies-01.png";
import logoWithRuby from "../images/logo-with-ruby.png";
import DateTimerPicker from "../../../common/DateTimerPicker";
import DatePicker from "../../../common/DatePickers";
import axios from "axios";
import { message } from "antd";
const HotelInformation = ({
  setDataShow,
  dataShow,
  phoneNo,
  currentBuyer,
  setCurrentBuyer,
  setPhoneNo,
  reload,
  setReload,
}) => {
  const [memberAdd, setMemberAdd] = useState(false);
  const [aadharFront, setAadharFront] = useState(null);
  const [aadharBack, setAadharBack] = useState(null);
  const [yourPhoto, setYourPhoto] = useState(null);
  const [guest_name, setGuest_name] = useState(null);
  const [remark, setRemark] = useState(null);
  const [hotel_check_in_date, setHotel_check_in_date] = useState("");
  const [hotel_check_out_date, setHotel_check_out_date] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleContinue = (e) => {
     e.preventDefault();
    if (memberAdd) {
        if (!aadharFront ||
           !aadharBack ||
           !yourPhoto ||
           !guest_name ||
           !remark ||
           !hotel_check_in_date ||
           !hotel_check_out_date) {
          
           setErrorMessage("Please fill in all fields");

      return;
          
           }else{
            const formData = new FormData();
            // formData.append("gender", gender);
            formData.append("hotel_check_in_date", hotel_check_in_date);
            formData.append("hotel_check_out_date", hotel_check_out_date);
            formData.append("visitor_remark", remark);
            formData.append("visitor_name", guest_name);
            formData.append("id_proof_front", aadharFront);
            formData.append("id_proof_back", aadharBack);
            formData.append("photo", yourPhoto);
             axios
               .post(
                 `${process.env.REACT_APP_API_END_POINT}/add-buyer-hotel`,
                 formData,
                 {
                   headers: {
                     barcode: localStorage.getItem("barcode"),
                   },
                 }
               )
               .then((resData) => {
                 message.success(resData.data.message);

                 // localStorage.setItem("barcode", resData.data.data.barcode);
               })
               .catch((error) => {
                 console.log("error:", error.message);
               });
           }
    }else{
        if (
          !remark ||
          !hotel_check_in_date ||
          !hotel_check_out_date
        ) {
          setErrorMessage("Please fill in all fields");

          return;
        } else {
          const formData = new FormData();
          // formData.append("gender", gender);
          formData.append("hotel_check_in_date", hotel_check_in_date);
          formData.append("hotel_check_out_date", hotel_check_out_date);
          formData.append("visitor_remark", remark);
          axios
            .post(
              `${process.env.REACT_APP_API_END_POINT}/add-buyer-hotel`,
              formData,
              {
                headers: {
                  barcode: localStorage.getItem("barcode"),
                },
              }
            )
            .then((resData) => {
              message.success(resData.data.message);

              // localStorage.setItem("barcode", resData.data.data.barcode);
            })
            .catch((error) => {
              console.log("error:", error.message);
            });
        }
    }
   
    setDataShow(dataShow + 1);
  };

  const handleContinueForMember = (e) => {
    if (memberAdd) {
      setMemberAdd(false);
    } else {
      setMemberAdd(true);
    }
  };

  useEffect(() => {
    localStorage.setItem("progress", dataShow);
  }, [dataShow]);

  const handleAadharFrontChange = (e) => {
    setAadharFront(e.target.files[0]);
  };

  const handleAadharBackChange = (e) => {
    setAadharBack(e.target.files[0]);
  };

  const handleYourPhotoChange = (e) => {
    setYourPhoto(e.target.files[0]);
  };
  useEffect(() => {
    console.log(
      "hotel_check_in_date,hotel_check_out_date,",
      hotel_check_in_date,
      hotel_check_out_date
    );
  }, [hotel_check_in_date, hotel_check_out_date]);
  return (
    <div>
      {" "}
      <div class="home-page">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="left-section">
                <div class="rubi-image">
                  <img class="destop-view" src={logo} alt="" />
                  <img class="destop-view size-n1" src={rubiesImage} alt="" />

                  {/* <!-- <img class="destop-view" src="images/jjs-logo-image.png" alt=""> --> */}
                  <img class="mobile-view" src={logoWithRuby} alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="right-section">
                <div class="right-top-section">
                  <form>
                    <div class="mb-3 destop-view">
                      <label for="exampleInputname" class="form-label ">
                        Hotel Check-in Date
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      {/* <DateTimerPicker/> */}
                      <DatePicker
                        setDate={setHotel_check_in_date}
                        lable={"Check-in date"}
                      />

                      {/* <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.
                                                                                            </div> --> */}
                    </div>
                    <div class="mb-3 destop-view">
                      <label for="exampleInputPassword1" class="form-label">
                        Hotel Check-out Date
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <DatePicker
                        setDate={setHotel_check_out_date}
                        lable={"Check-out date"}
                      />
                    </div>
                    <div class="mb-3 mobile-view">
                      {/* <DateTimerPicker/> */}
                      <DatePicker
                        setDate={setHotel_check_in_date}
                        lable={"Check-in date"}
                      />

                      {/* <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.
                                                                                            </div> --> */}
                    </div>
                    <div class="mb-3 mobile-view">
                      <DatePicker
                        setDate={setHotel_check_out_date}
                        lable={"Check-out date"}
                      />
                    </div>

                    <div class="mb-3 destop-view">
                      <label for="exampleInputPassword1" class="form-label">
                        Remark
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="mail"
                        class="form-control"
                        id="exampleInputPassword1"
                        required
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                      />
                    </div>
                    <div class="mb-3 mobile-view">
                      <input
                        type="mail"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="Remark"
                        required
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                      />
                    </div>
                    <button
                      onClick={handleContinueForMember}
                      class="btn btn-primary btn-primary-01 "
                    >
                      <a>{memberAdd ? "Remove" : "Add Member"}</a>
                    </button>
                    {memberAdd && (
                      <>
                        {" "}
                        <div class="mb-3 destop-view">
                          <label for="exampleInputPassword1" class="form-label">
                            Name of Guest
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="mail"
                            class="form-control"
                            id="exampleInputPassword1"
                            required
                            value={guest_name}
                            onChange={(e) => setGuest_name(e.target.value)}
                          />
                        </div>
                        <div class="mb-3 mobile-view">
                          <input
                            type="mail"
                            class="form-control"
                            id="exampleInputPassword1"
                            placeholder=" Hotel Check-out Date"
                            required
                            value={guest_name}
                            onChange={(e) => setGuest_name(e.target.value)}
                          />
                        </div>
                        <div
                          action="/upload"
                          method="post"
                          enctype="multipart/form-data"
                        >
                          <label>
                            upload Aadhar Front
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="file"
                            name="fileUpload"
                            id="fileUpload"
                            onChange={handleAadharFrontChange}
                            required
                          />
                        </div>
                        <div
                          action="/upload"
                          method="post"
                          enctype="multipart/form-data"
                        >
                          <label>
                            upload Aadhar Back
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="file"
                            name="fileUpload"
                            id="fileUpload"
                            onChange={handleAadharBackChange}
                            required
                          />
                        </div>
                        <div
                          action="/upload"
                          method="post"
                          enctype="multipart/form-data"
                        >
                          <label>
                            upload Your Photo
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="file"
                            name="fileUpload"
                            id="fileUpload"
                            onChange={handleYourPhotoChange}
                          />
                        </div>
                      </>
                    )}
                    {errorMessage && (
                      <div style={{ color: "red" }}>{errorMessage}</div>
                    )}
                    <div class="two-btn">
                      <button
                        class="btn btn-primary btn-primary-01 "
                        onClick={(e) => {
                          e.preventDefault();
                          setDataShow(dataShow - 1);
                        }}
                      >
                        <a>
                          Back
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                            />
                          </svg>
                        </a>
                      </button>
                      <button
                        type="submit"
                        onClick={handleContinue}
                        class="btn btn-primary btn-primary-01"
                      >
                        <a>
                          Continue
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                            />
                          </svg>
                        </a>
                      </button>
                    </div>
                  </form>

                  <div class="progress">
                    <div
                      class="progress-bar "
                      role="progressbar"
                      aria-label="Default striped example"
                      style={{
                        width: " 20%",
                        ariaValuenow: "10",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelInformation;
