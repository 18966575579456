import React, { useEffect, useState } from "react";
import logo from "../images/logo-01.png";
import rubiesImage from "../images/many-rubies-01.png";
import logoWithRuby from "../images/logo-with-ruby.png";
import axios from "axios";
const DetailsPresent = ({
  setDataShow,
  dataShow,
  currentBuyer,
  setCurrentBuyer,
  reload,
  setPhoneNo,
  setReload,
}) => {
  const [user, setUser] = useState(null);
  const handleContinue = (e) => {
    e.preventDefault();
    setDataShow(dataShow + 1);
  };
  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_END_POINT}/get-detail-buyer`, "", {
        headers: {
          barcode: localStorage.getItem("barcode"),
        },
      })
      .then((resData) => {
        // localStorage.setItem("barcode", resData.data.data.barcode);
        console.log("hello", resData.data.data);
        localStorage.removeItem("barcode");
        setPhoneNo("");
        setUser(resData.data.data);
      })
      .catch((error) => {
        console.log("error:", error.message);
      });
  }, []);
  useEffect(() => {
    localStorage.setItem("progress", dataShow);
  }, [dataShow]);
  return (
    <div>
      {" "}
      <div className="home-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="left-section">
                <div className="rubi-image">
                  <img className="destop-view" src={logo} alt="" />
                  <img
                    className="destop-view size-n1"
                    src={rubiesImage}
                    alt=""
                  />

                  {/* <!-- <img className="destop-view" src="images/jjs-logo-image.png" alt=""> --> */}
                  <img className="mobile-view" src={logoWithRuby} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="right-section">
                <div className="right-top-section">
                  <form>
                    <div className="detials">
                      <h2>Confirm your Details</h2>
                    </div>
                    {user ? (
                      <data className="cutomer-details">
                        <span>name :- {user.visitor_name}</span>
                        <span>number :-{user.mobile_number}</span>
                        {/* <span>birth year :- 1989</span> */}
                        <span>e-mail address :{user.email}</span>
                        <span>Company Name :- {user.company_name}</span>
                        <span>Designation :- {user.designation}</span>
                        <span>City :- {user.city}</span>
                        <span>Address :- {user.address}</span>
                        <span>Pincode :- {user.pin_code}</span>
                        <span>GST Number :- {user.gst_number}</span>
                        <span>
                          Hotel Check-in :-{" "}
                          {user.hotel_detail.hotel_check_in_date}
                        </span>{" "}
                        <span>
                          Hotel Check-Out :-{" "}
                          {user.hotel_detail.hotel_check_out_date}
                        </span>
                        <span>
                          arrival city :- {user.travel_details.arival_city}
                        </span>
                        <span>
                          arrival mode :- {user.travel_details.travel_mode}
                        </span>
                        {user.travel_details.travel_mode !== "Self" && (
                          <>
                            <span>PNR :- {user.travel_details.arival_pnr}</span>
                            <span>
                              Date & Time :-{" "}
                              {user.travel_details.arival_date_time}
                            </span>
                            <span>
                              Name :- {user.travel_details.train_flight_name}
                            </span>
                            <span>
                              Number:- {user.travel_details.train_flight_number}
                            </span>
                          </>
                        )}
                        <span>Remark :- {user.visitor_remark}</span>
                        {/* <span>Invited Gu :- {user.visitor_remark}</span> */}
                      </data>
                    ) : (
                      "Loading..."
                    )}
                    <div className="two-btn">
                      <button
                        type="submit"
                        className="btn btn-primary btn-primary-01 "
                        onClick={(e) => {
                          setDataShow(1);

                          e.preventDefault();
                        }}
                      >
                        <a>Thanks!</a>
                      </button>
                      {/* <button
                        className="btn btn-primary btn-primary-01"
                        onClick={handleContinue}
                      >
                        <a>
                          Continue
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                            />
                          </svg>
                        </a>
                      </button> */}
                    </div>
                  </form>
                </div>
                {/* <div className="progress">
                  <div
                    className="progress-bar "
                    role="progressbar"
                    aria-label="Default striped example"
                    style={{
                      width: " 20%",
                      ariaValuenow: "10",
                      ariaValuemin: "0",
                      ariaValuemax: "100",
                    }}
                  ></div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsPresent;
