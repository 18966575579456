import React, { useEffect, useState } from "react";
import logo from "../images/logo-01.png";
import rubiesImage from "../images/many-rubies-01.png";
import vectorImage from "../images/vector-image-01.png";
import CryptoJS from "crypto-js";
import axios from "axios";
import logoWithRuby from "../images/logo-with-ruby.png";
import RedirectForm from "./RedirectForm";
const PaymentPage = ({
  setDataShow,
  dataShow,
  encrypted_data,
  setEncrypted_data,
}) => {
  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [encryptedData, setEncryptedData] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const handleContinue = (e) => {
    e.preventDefault();
    setDataShow(dataShow + 1);
  };
  useEffect(() => {
    localStorage.setItem("progress", dataShow);
  }, [dataShow]);
  const processPayment = async () => {
    console.log(
      "hello ia me eh re",
      `https://jjs.samaksh.in/pay-now?order_id=${localStorage.getItem(
        "barcode"
      )}`
    );
    localStorage.setItem(
      "urlrul",
      `https://jjs.samaksh.in/pay-now?order_id=${localStorage.getItem(
        "barcode"
      )}`
    );
    const barcode = localStorage.getItem("barcode");
    const url = `https://jjs.samaksh.in/pay-now?order_id=${barcode}`;
    window.open(url, "_blank");
    // try {
    //   setShowPaymentPage(true);
    //   // Handle the response
    // } catch (error) {
    //   console.error("Payment Error:", error);
    // }
  };
  useEffect(() => {
    const formData = new FormData();
    formData.append("barcode", localStorage.getItem("barcode"));

    axios
      .post(`https://jjs.samaksh.in/api/visitor/v2/verify-otp`, formData)
      .then((resData) => {
        const { city, address, pincode } = resData.data.visitor[0];
        setCity(city);
        setAddress(address);
        setPincode(pincode);
      });
  }, []);
  return (
    <div>
      {" "}
      {showPaymentPage ? (
        <RedirectForm
          encryptedData={encryptedData}
          accessCode={accessCode}
          encrypted_data={encrypted_data}
        />
      ) : (
        <div className="home-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="left-section">
                  <div className="rubi-image">
                    <img className="destop-view" src={logo} alt="" />
                    <img
                      className="destop-view size-n1"
                      src={rubiesImage}
                      alt=""
                    />

                    {/* <!-- <img className="destop-view" src="images/jjs-logo-image.png" alt=""> --> */}
                    <img className="mobile-view" src={logoWithRuby} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="right-section">
                  <div className="right-top-section">
                    <form>
                      <div className="mb-3 destop-view">
                        <label for="exampleInputname" className="form-label ">
                          City
                        </label>
                        <input
                          type="Company namee"
                          className="form-control"
                          id="exampleInputName"
                          aria-describedby="name"
                          required
                          value={city}
                        />
                        {/* <!-- <div id="emailHelp" className="form-text">We'll never share your email with anyone else.
                                                                                            </div> --> */}
                      </div>
                      <div className="mb-3 destop-view">
                        <label
                          for="exampleInputPassword1"
                          className="form-label"
                        >
                          Address
                        </label>
                        <input
                          type="mail"
                          className="form-control"
                          id="exampleInputPassword1"
                          required
                          value={address}
                        />
                      </div>
                      <div className="mb-3 destop-view">
                        <label
                          for="exampleInputPassword1"
                          className="form-label"
                        >
                          pincode
                        </label>
                        <input
                          type="mail"
                          className="form-control"
                          id="exampleInputPassword1"
                          required
                          value={pincode}
                        />
                      </div>
                      <div className="mb-3 mobile-view">
                        <input
                          type="name"
                          className="form-control"
                          id="exampleInputName"
                          aria-describedby="datetime"
                          placeholder="Company Name"
                          required
                          value={city}
                        />
                      </div>
                      <div className="mb-3 mobile-view">
                        <input
                          type="mail"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="GST number"
                          required
                          value={address}
                        />
                      </div>
                      <div className="mb-3 mobile-view">
                        <input
                          type="mail"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="pincode"
                          required
                          value={pincode}
                        />
                      </div>
                      <input
                        type="radio"
                        id="option1"
                        name="options"
                        value="option1"
                      />
                      <span>i agree all statement in terms and condition</span>

                      <div className="two-btn">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setDataShow(dataShow - 1);
                          }}
                          className="btn btn-primary btn-primary-01 "
                        >
                          <a>
                            Back
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-arrow-right-short"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                              />
                            </svg>
                          </a>
                        </button>
                        <button
                          onClick={handleContinue}
                          className="btn btn-primary btn-primary-01"
                        >
                          <a>
                            Continue
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-arrow-right-short"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                              />
                            </svg>
                          </a>
                        </button>
                      </div>
                    </form>

                    <div className="progress">
                      <div
                        className="progress-bar "
                        role="progressbar"
                        aria-label="Default striped example"
                        style={{
                          width: " 20%",
                          ariaValuenow: "10",
                          ariaValuemin: "0",
                          ariaValuemax: "100",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="box">
                  <img src={vectorImage} alt="" />

                  {/* <h6>www.jjsjaipur.com says</h6> */}
                  <span>
                    Please Pay Rs. 1,000 to complete the Registration, using
                    your choice of payment method
                  </span>
                  {/* <button className="ok" onClick={processPayment}>
                    Proceed
                  </button> */}
                  <a
                    href={`https://jjs.samaksh.in/pay-now?order_id=${localStorage.getItem(
                      "barcode"
                    )}`}
                    // target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary"
                  >
                    Pay now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
