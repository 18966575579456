import React, { useState, useEffect } from "react";
import Logo from "../../HtmlMainPage/images/jjs-logo-image001.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { message } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

function SuperAdminAreaLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [update, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleChange();
    }
  };

  useEffect(() => {
    if (localStorage.getItem("jff_token")) {
      navigate(`/superadmin/dashboard`);
    }
  }, []);

  const handleChange = () => {
    setIsLoading(true);
    if (!email) {
      message.error("Enter username or email");
      setIsLoading(false);
      return;
    }
    if (!password) {
      message.error("Enter password");
      setIsLoading(false);
      return;
    }

    axios
      .post(`${process.env.REACT_APP_API_END_POINT}/login`, { email, password })
      .then((response) => {
        // Insert token and category in local storage
        localStorage.setItem("jff_token", response.data.token);
        console.log("asdaddsada", response.data.category.permissions);
        localStorage.setItem(
          "permission",
          JSON.stringify(response.data.category.permissions)
        );
        localStorage.setItem("user_id", response.data.category._id);
        localStorage.setItem("type_of_user", "/superadmin/login");
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      })
      .finally(async () => {
        let data = localStorage.getItem("permission");
        data = JSON.parse(data);
        console.log("data", data);
        let nameArray = [];
        axios
          .post(`${process.env.REACT_APP_API_END_POINT}/get-permission-name`, {
            permission: data,
          })
          .then((res) => {
            console.log(".data.data", res.data.data);
            nameArray = res.data.data;
            if (nameArray.includes("super_admin")) {
              console.log("running inside if");
              //   axios.post(`${process.env.REACT_APP_SEND_GRETTING_URL}/jgc-cover-api`,{

              //     phone_number:"917048996395",
              //     source:"jgc_jaipur",
              //     name:"anilesh mathur",
              //     message:"Hello Prakhar dhuria,welcome to the Jaipur Garment Club"
              // },{
              //   headers:{
              //     id:process.env.REACT_APP_SEND_WABA_ID
              //   }
              // }).then((res)=>{
              //   console.log(res.data)
              // })
              navigate("/superadmin/dashboard");
            } else {
              message.error("Access Denied");
            }
          });
      });
  };

  return (
    <>
      <div className="container">
        <img
          src={Logo}
          alt="logo-img"
          className="my-1 mx-auto d-flex"
          style={{ height: "200px", width: "200px" }}
        />
        <div className="exhibitorlogin">
          <div className="exhibitorloginbody">
            <div className="fw-semi-bold fs-3">Welcome to Hosted Buyer Admin</div>
            <Form className="my-4">
              <Form.Control
                type="username"
                placeholder="Username"
                className="my-4"
                onChange={(e) => setEmail(e.target.value)}
                required
                onKeyDown={handleKeyDown}
              />
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                required
                onKeyDown={handleKeyDown}
              />
              <Button className="my-4 btn-block" onClick={handleChange}>
                {isLoading ? <Spinner animation="border" size="sm" /> : "Login"}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default SuperAdminAreaLogin;
