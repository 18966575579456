import React, { useEffect, useState } from "react";
import logo from "../images/logo-01.png";
import rubiesImage from "../images/many-rubies-01.png";
import logoWithRuby from "../images/logo-with-ruby.png";
import axios from "axios";
import { message } from "antd";

const MissingDetailsOne = ({
  setDataShow,
  dataShow,
  currentBuyer,
  setCurrentBuyer,
  reload,
  setReload,
}) => {
  const [designation, setDesignation] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [dealIn, setDealIn] = useState("");
  const [photo, setPhoto] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [pin_code, setPin_code] = useState("");
  const [postal_address, setPostal_address] = useState("");
  const designationArray = ["Select","CEO", "Director", "Owner", "Staff"];
  const handleCategoryChange = (e) => {
    setDesignation(e.target.value);
  };

  // const handleProfessionChange = (e) => {
  //   setProfession(e.target.value);
  // };

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
  };

  const handleGstNumberChange = (e) => {
    setGstNumber(e.target.value);
  };

  const handleDealInChange = (e) => {
    setDealIn(e.target.value);
  };

  const handlePhotoChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleContinue = (e) => {
    console.log(
      "adasdassdsddad",
      designation,
      companyName,
      gstNumber,
      dealIn,
      photo,
      errorMessage,
      pin_code,
      postal_address
    );
    e.preventDefault();
    if (
      !designation ||
      !companyName ||
      !postal_address ||
      !gstNumber ||
      !photo ||
      !pin_code
    ) {
      setErrorMessage("Please fill in all fields");
      return;
    }
    const formData = new FormData();
    formData.append("company_name", companyName);
    formData.append("designation", designation);
    formData.append("address", postal_address);
    formData.append("gst_number", gstNumber);
    formData.append("pin_code", pin_code);
    formData.append("gst_photo", photo);
    // formData.append("barcode", barCode)
    console.log("asdassasasdsdadadasadasdasddasaasdsdasd", currentBuyer);
    axios
      .post(
        `${process.env.REACT_APP_API_END_POINT}/edit-profession-buyer`,
        formData,
        {
          headers: {
            barcode: localStorage.getItem("barcode"),
          },
        }
      )
      .then((resData) => {
        message.success(resData.data.message);
        if (resData.data.message === "Otp Send") {
          setDataShow(dataShow + 1);
        } else {
          return;
        }
      })
      .catch((error) => {
        console.log("error:", error.message);
      });

    setDataShow(dataShow + 1);
  };

  useEffect(() => {
    localStorage.setItem("progress", dataShow);
  }, [dataShow]);

  const handlePincodechange = (e) => {
    const value = e.target.value;

    // Check if the value contains only numbers
    if (!/^\d*$/.test(value)) {
      setErrorMessage("pincode contain numbers only");
      // If the value contains non-numeric characters, don't update the state
      return;
    }

    // Update the state only if the value is numeric
    setPin_code(value);
  };

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_END_POINT}/get-detail-buyer`, "", {
        headers: {
          barcode: localStorage.getItem("barcode"),
        },
      })
      .then((resData) => {
        // localStorage.setItem("barcode", resData.data.data.barcode);
        console.log("hello", resData.data.data);
        setCompanyName(resData.data.data.company_name);
        setDesignation(resData.data.data.designation);
        setPhoto(resData.data.data.gst_photo);
        setPin_code(resData.data.data.pin_code);
        setPostal_address(resData.data.data.address);
        setGstNumber(resData.data.data.gst_number);
      })
      .catch((error) => {
        console.log("error:", error.message);
      });
  }, []);

  return (
    <div>
      <div className="home-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="left-section">
                <div className="rubi-image">
                  <img className="destop-view" src={logo} alt="" />
                  <img
                    className="destop-view size-n1"
                    src={rubiesImage}
                    alt=""
                  />
                  <img className="mobile-view" src={logoWithRuby} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="right-section">
                <div className="right-top-section">
                  <form>
                    <div className="mb-3 destop-view">
                      <label htmlFor="exampleInputname" className="form-label">
                        <span style={{ color: "red" }}>*</span>
                        Company Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName"
                        aria-describedby="name"
                        required
                        value={companyName}
                        onChange={handleCompanyNameChange}
                      />
                    </div>
                    <div className="mb-3 mobile-view">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName"
                        aria-describedby="datetime"
                        placeholder="Company Name"
                        required
                        value={companyName}
                        onChange={handleCompanyNameChange}
                      />
                    </div>
                    <label htmlFor="cars">
                      Designation
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="mb-3 form-control"
                      onChange={handleCategoryChange}
                      value={designation}
                    >
                      {designationArray.map((item, index) => (
                        <option key={index}>{item}</option>
                      ))}
                    </select>
                    {/* <label htmlFor="cars">Profession</label>
                    <select
                      className="mb-3 form-control"
                      onChange={handleProfessionChange}
                      value={profession}
                    >
                      <option>Builder</option>
                      <option>Contractor</option>
                      <option>Plaster</option>
                      <option>Tile</option>
                      <option>Electrician</option>
                      <option>Plumber</option>
                      <option>POP</option>
                    </select> */}

                    <div className="mb-3 destop-view">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        Postal Address (For Sending you show badge)
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        required
                        value={postal_address}
                        onChange={(e) => setPostal_address(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 destop-view">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        GST Number
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        required
                        value={gstNumber}
                        onChange={handleGstNumberChange}
                      />
                    </div>
                    <div className="mb-3 destop-view">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        Pincode
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        required
                        value={pin_code}
                        onChange={handlePincodechange}
                      />
                    </div>
                    <div className="mb-3 mobile-view">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        required
                        placeholder=" Postal Address (For Sending you show badge)"
                        value={postal_address}
                        onChange={(e) => setPostal_address(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 mobile-view">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        required
                        placeholder="pincode"
                        value={pin_code}
                        onChange={handlePincodechange}
                      />
                    </div>

                    <div className="mb-3 mobile-view">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="GST number"
                        required
                        value={gstNumber}
                        onChange={handleGstNumberChange}
                      />
                    </div>
                    {/* <label htmlFor="cars">Deal In</label>
                    <select
                      className="mb-3 form-control"
                      onChange={handleDealInChange}
                      value={dealIn}
                    >
                      <option>Builder</option>
                      <option>Contractor</option>
                      <option>Plaster</option>
                      <option>Tile</option>
                      <option>Electrician</option>
                      <option>Plumber</option>
                      <option>POP</option>
                    </select> */}
                    <div
                      action="/upload"
                      method="post"
                      encType="multipart/form-data"
                    >
                      <label>
                        Upload Photo
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="file"
                        name="fileUpload"
                        id="fileUpload"
                        onChange={handlePhotoChange}
                      />
                    </div>
                    {errorMessage && (
                      <div style={{ color: "red" }}>{errorMessage}</div>
                    )}
                    <div className="two-btn">
                      <button className="btn btn-primary btn-primary-01">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            setDataShow(dataShow - 1);
                          }}
                        >
                          Back
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                            />
                          </svg>
                        </a>
                      </button>
                      <button
                        className="btn btn-primary btn-primary-01"
                        onClick={handleContinue}
                      >
                        Continue
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-right-short"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                          />
                        </svg>
                      </button>
                    </div>
                  </form>
                  <div className="progress">
                    <div
                      class="progress-bar "
                      role="progressbar"
                      aria-label="Default striped example"
                      style={{
                        width: "66.64%",
                        ariaValuenow: "10",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissingDetailsOne;
