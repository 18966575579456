import React, { useEffect } from "react";

const Thanks = () => {
  useEffect(() => {
    localStorage.removeItem("barcode");
  }, []);
  return (
    <div>
      {" "}
      <div className="container mt-5">
        <div className="card bg-light p-4 text-center m-5">
          <h1 className="card-title">Thank you!</h1>
          <p className="card-text">
            Your registration is complete at jjsjaipur.com.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Thanks;
