import React, { useEffect, useState } from "react";
import logo from "../images/logo-01.png";
import rubiesImage from "../images/many-rubies-01.png";
import logoWithRuby from "../images/logo-with-ruby.png";
import vectorImage from "../images/vector-image-01.png";
import axios from "axios";
import RedirectForm from "./RedirectForm";
const PaymentDetail = ({ setDataShow, dataShow }) => {
  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const [encryptedData, setEncryptedData] = useState('');
  const [accessCode, setAccessCode] = useState('');

  ;
  // 👇Payment Gateway
  let working_key = "20E863DBE2667550912CF62097FD0114"; //Shared by CCAVENUES
  let access_code = "AVDF69JK75CH80FDHC"; //Shared by CCAVENUES
  const processPayment = async () => {
    try {
     setShowPaymentPage(true)
      // Handle the response
     
    } catch (error) {
      console.error("Payment Error:", error);
    }
  };
  useEffect(() => {
    localStorage.setItem("progress", dataShow);
  }, [dataShow]);
  return (
    <div>
      {" "}
      {showPaymentPage ? (
        <RedirectForm
          encryptedData={encryptedData}
          accessCode={accessCode}
        />
      ) : (
        <div class="home-page">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="left-section">
                  <div class="rubi-image">
                    <img class="destop-view" src={logo} alt="" />
                    <img class="destop-view size-n1" src={rubiesImage} alt="" />

                    {/* <!-- <img class="destop-view" src="images/jjs-logo-image.png" alt=""> --> */}
                    <img class="mobile-view" src={logoWithRuby} alt="" />
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="right-section">
                  <div class="right-top-section">
                    <form>
                      <div class="detials">
                        <h2>Confirm your Details</h2>
                      </div>
                      <data class="cutomer-details">
                        <span>name :- rajesh kumar</span>
                        <span>number :- +91 9878675645</span>
                        <span>birth year :- 1989</span>
                        <span>e-mail address :- admin@12345</span>
                        <span>category :- gems and jewelary</span>
                        <span>profession :- menufacture</span>
                        <span>deal in :- 12</span>
                        <span>gST number :- 12</span>
                        <span>company name :- yejjsd</span>
                        <span>city :- jaipur</span>
                        <span>address :- 4, ramvihar jaipur</span>
                        <span>pincode :- 444095</span>
                      </data>
                      <div class="two-btn">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setDataShow(dataShow - 1);
                          }}
                          class="btn btn-primary btn-primary-01 "
                        >
                          <a href="verify.html">
                            Back
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-arrow-right-short"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                              />
                            </svg>
                          </a>
                        </button>
                        <button
                          onClick={processPayment}
                          class="btn btn-primary btn-primary-01"
                        >
                          <a href="verify.html">
                            Continue
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-arrow-right-short"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                              />
                            </svg>
                          </a>
                        </button>
                      </div>
                    </form>
                  </div>
                  <div class="progress">
                    <div
                      class="progress-bar "
                      role="progressbar"
                      aria-label="Default striped example"
                      style={{
                        width: " 20%",
                        ariaValuenow: "10",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="box">
                  <img src={vectorImage} alt="" />
                  <h6>www.jjsjaipur.com says</h6>
                  <span>
                    you have to pay an entry fee of 1000/- rupees per person.
                    your resistration code is emlrpz
                  </span>
                  <button class="ok" onClick={processPayment}>
                    ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentDetail;
