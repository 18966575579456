import { useState } from 'react';

function Base64Image({ base64String }) {
  const [blobUrl, setBlobUrl] = useState('');

  // Convert base64 string to Blob
  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  // Handle click to generate Blob URL
  const handleClick = () => {
    if (base64String) {
      const mimeType = base64String.match(/^data:(.*);base64,/)?.[1]; // Extract MIME type
      const blob = base64ToBlob(base64String, mimeType);
      const url = URL.createObjectURL(blob);
      setBlobUrl(url); // Set the URL for the anchor tag
    }
  };

  return (
    <div>
    
        <a href={blobUrl || "#"} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
          <img src={base64String} alt="Base64 Preview" style={{ maxWidth: '300px', height:'auto', display: 'block', objectFit:'contain', margin:'0 auto' }} />
        </a>

      {/* <a href={blobUrl || "#"} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
        View Existing
      </a> */}
    </div>
  );
}

export default Base64Image;
