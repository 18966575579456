import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

export default function DatePickers({ setDate,lable }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          label={lable}
          onChange={(date) => {
            const parsedDate = new Date(date);
            setDate(moment(parsedDate).format("ll"));
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
