import React from "react";
import { useLocation } from "react-router-dom";
import PrintPage from "./PrintPage";

const SuccessfulRedirection = () => {
  // const location = useLocation();
  // const postData = new URLSearchParams(location.search);

  // console.log("postData", postData);
  // // Access the POST data using get method
  // const dataValue = postData.get("dataKey");

  return (
    <>
     
      <PrintPage
        barcode={localStorage.getItem("barcode")}
        text={"Thank you for your payment."}
      />
    </>
  );
};

export default SuccessfulRedirection;
