import React, { useEffect } from "react";

const RedirectForm = ({ encryptedData, accessCode, encrypted_data }) => {
  useEffect(() => {
    console.log(
      "encrypted_data",
      `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${encrypted_data}&access_code=AVDF69JK75CH80FDHC`
    );
    const form = document.createElement("form");
    form.method = "post";
    form.name = "redirect";
    form.action = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${encrypted_data}&access_code=AVDF69JK75CH80FDHC`;

    const encRequestInput = document.createElement("input");
    encRequestInput.type = "hidden";
    encRequestInput.name = "encRequest";
    encRequestInput.value = encryptedData;

    const accessCodeInput = document.createElement("input");
    accessCodeInput.type = "hidden";
    accessCodeInput.name = "access_code";
    accessCodeInput.value = accessCode;

    form.appendChild(encRequestInput);
    form.appendChild(accessCodeInput);

    document.body.appendChild(form);

    form.submit();

    return () => {
      document.body.removeChild(form);
    };
  }, [encrypted_data]);

  return null;
};

export default RedirectForm;
