import React, { useEffect, useState } from 'react';
import './css/style.css'; // Assuming you have a CSS file for styling
import { MultiSelect } from "react-multi-select-component";
import Clip from './images/clip.png'
import { message } from 'antd'
import axios from 'axios';
import Base64Image from './Base64Image';
import logo from '../jjsRegistration/images/the.png'
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FaCreditCard } from "react-icons/fa";
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: "black", // Changing the option text color to black
    backgroundColor: state.isSelected ? "#f0f0f0" : "#fff", // You can also customize background color
  }),
};
const Page3 = ({ step, setStep }) => {
  const [category, setCategory] = useState('');
  const [profession, setProfession] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [dealsIn, setDealsIn] = useState([]);
  const [gstNo, setGstNo] = useState('');
  const [file, setFile] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [deals_ids, set_deals_ids] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null)
  const [passport_number, setPassport_number] = useState(null)
  const [passport_expiry, setPassport_expiry] = useState(null)
  const [passport_issue_country, setPassport_issue_country] = useState(null)
  const [photoName, setPhotoName] = useState(null)
  const [allreadyPresent, setAllreadyPresent] = useState(null)
  const [loading, setLoading] = useState(false)
  const [agree, setAgree] = useState(true);
  const [company_address, setCompany_addess] = useState(null)
  const [company_city, setCompany_city] = useState(null)
  const [company_state, setCompany_state] = useState(null)
  const [gst_data_present, setGst_data_present] = useState(null)
  const navigate = useNavigate();
  const [payment, setPayment] = useState(false)
  const [paymentText, setPaymentText] = useState(null)
  const [payment_status, setPayment_status] = useState("pay_now")
  // Online Javascript Editor for free
  // Write, Edit and Run your Javascript code using JS Online Compiler
  const countries = [
    "Select",
    "Afghanistan",
    "Åland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, The Democratic Republic of The",
    "Cook Islands",
    "Costa Rica",
    "Cote D'ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-bissau",
    "Guyana",
    "Haiti",
    "Heard Island and Mcdonald Islands",
    "Holy See (Vatican City State)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic of",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Democratic People's Republic of",
    "Korea, Republic of",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libyan Arab Jamahiriya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia, The Former Yugoslav Republic of",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestinian Territory, Occupied",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Pierre and Miquelon",
    "Saint Vincent and The Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and The South Sandwich Islands",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan, Province of China",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Viet Nam",
    "Virgin Islands, British",
    "Virgin Islands, U.S.",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  // Transforming the list into the required format
  const countryArray = countries.map(country => ({
    value: country,
    label: country
  }));


  console.log("Try programiz.pro");
  const category_list = [{ value: "select", label: "Select Category" }, { value: "trader", label: "Trade Jewellers" },
  { value: "media", label: "Media" },

  { value: "nri", label: "NRI" },
  { value: "visitor", label: "General Visitor" },

  ]

  const profession_visistor_list = [{ value: "select_profession", label: "Select Profession" },
  { label: "Business", value: "2" },
  { label: "Professional", value: "10" },
  { label: "Service", value: "13" },
  { label: "Housewife", value: "4" },
  { label: "Student", value: "14" },
  { label: "Publications", value: "11" },
  { label: "Others", value: "9" }
  ]


  const profession_list = [{ value: "select_profession", label: "Select Profession" }, { value: "5", label: "Manufacturer" },
  { value: "15", label: "Trader" },

  { value: "1", label: "Broker" },

  ]
  const handleCategoryChange = (e) => {
    if (e.target.value !== "select") {

      setCategory(e.target.value)
    };
  }
  const handleProfessionChange = (e) => {
    if (e.target.value !== "select") {
      setProfession(e.target.value);
    }
  }

  const handleCompanyNameChange = (e) => setCompanyName(e.target.value);
  const handleDealsInChange = (e) => setDealsIn([...e.target.selectedOptions].map(option => option.value));
  const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  function checkRegex(input) {
    return regex.test(input);
  }
  function convertToUpperCase(input) {
    return input.replace(/[a-z]/gi, (char) => char.toUpperCase());
  }
  const handleGstNoChange = (e) => {
    setGstNo(convertToUpperCase(e.target.value));
    const value = e.target.value;
    if (checkRegex(value)) {
      setErrorMessage("");
      console.log("asdasdasd", e.target.value);
    } else {
      setErrorMessage("provide Valid GSTIN");
    }
  };


  useEffect(() => {

    if (gstNo?.length === 15 && checkRegex(gstNo)) {
      // setReloadShow(true);
      // ;

      axios
        .get(
          `${process.env.REACT_APP_API_END_POINT}/check-gst-validation/${gstNo}`
        )
        .then((resData) => {
          // setReloadShow(false);
          // console.log(
          //   "hello i am here to see the test daeeed",
          //   resData.data.message,
          //   resData.data.data.lgnm,
          //   resData.data.data.pradr.adr
          // );
          // if (resData.data.message === "GSTIN  found.") {
          console.log("gst find or not", resData.data);
          if (resData.data.flag === false) {
            setErrorMessage(resData.data.message);
            return;
          }
          setErrorMessage("");
          setGst_data_present(resData.data)
          setCompanyName(resData.data.company);
          setCompany_addess(resData.data.address);
          setCompany_city(resData.data.city);
          setCompany_state(resData.data.state);


          // }
        });
    }
  }, [gstNo]);

  const handlePhotoChange = (e) => {
    setPhotoName(e.target.files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      setFile(base64String);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleFileChange = (e) => setFile(e.target.files);
  const build_in_list = [
    { label: "Gold", value: "1" },
    { label: "Silver", value: "2" },
    { label: "Diamond", value: "3" },
    { label: "Costume/Fashion", value: "4" },
    { label: "Precious Stone", value: "5" },
    { label: "Semi-Precious Stones", value: "6" },
    { label: "Synthetic Stones", value: "7" },
    { label: "Others", value: "8" },
  ];
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log({
      category,
      profession,
      companyName,
      dealsIn,
      gstNo,
      file,
    });
  };


  useEffect(() => {
    const formData = new FormData();
    formData.append("barcode", localStorage.getItem("barcode"));

    axios
      .post(`https://jjs.samaksh.in/api/visitor/v2/verify-otp`, formData)
      .then((resData) => {
        const {
          company,
          professions,
          profession_id,
          category,
          gst,
          media_gst_membership_no,
        } = resData.data.visitor[0];
        console.log("jello", resData.data.visitor[0]?.category)
        if (category) {

        }
        setAllreadyPresent(resData.data.visitor[0])


      });

  }, []);
  useEffect(() => {
    if (allreadyPresent) {
      console.log("hello allreadyPresent", allreadyPresent.country)
      setCompanyName(allreadyPresent?.company)
      if (allreadyPresent?.country !== "India") {
        setCategory('nri')

      } else {

        setCategory(allreadyPresent?.category)
      }
      setProfession((allreadyPresent?.category === 'trader' ? profession_list : profession_visistor_list).find(item => parseInt(item.value) === parseInt(allreadyPresent?.profession))?.value);
      setFile(allreadyPresent?.gst);
      const filteredList = build_in_list.filter((item) =>
        allreadyPresent.professions.includes(parseInt(item.value))
      );

      setSelectedIds(filteredList ? filteredList : []);
      setGstNo(allreadyPresent?.media_gst_membership_no)
      setPassport_expiry(allreadyPresent?.ped)
      setPassport_issue_country(allreadyPresent?.country_of_issue || allreadyPresent?.country)



      console.log(allreadyPresent, "(allreadyPresent?.category === 'trader' ? profession_list : profession_visistor_list).find(item => parseInt(item.value) === parseInt(allreadyPresent?.profession))?.label", (allreadyPresent?.category === 'trader' ? profession_list : profession_visistor_list).find(item => parseInt(item.value) === parseInt(allreadyPresent?.profession))?.label)
    }
  }, [allreadyPresent])
  useEffect(() => {
    console.log("profession", profession)
  }, [profession])

  const handleNextStep = (e) => {
    setLoading(true)
    e.preventDefault();
    console.log("pro", profession)
    if (!category) {
    setLoading(false)

      return message.error("Please Select The Category")
    } else if (!profession && (category === "trader" || category === "visitor")) {
    setLoading(false)

      return message.error("Please Select The Profession")

    } else if (deals_ids.length === 0 && category === "trader") {
    setLoading(false)

      return message.error("Please Choose Deals In")

    } else if ((!passport_issue_country && category === "nri") || (passport_issue_country === "Select" && category === "nri")) {
    setLoading(false)

      return message.error("Please Choose Country of Issue")

    }
    // if(category==="trader" && (!profession || !companyName || !selectedIds || !gstNo || !gst)){


    // }else if(category==="media" &&){

    // }else if(category==="nri" &&){

    // }else if(category==="visitor" && ){

    // }
    // Handle form submission

    const formData = new FormData();
    for (let i = 0; i < selectedIds.length; i++) {
      formData.append("professions[]", selectedIds[i].value);
    }
    formData.append("step", 4);
    formData.append("category", category);

    // formData.append("professions[]", deals_ids.map(item => parseInt(item, 10)));
    formData.append("profession", profession);
    formData.append("company", companyName);
    // formData.append("professions[]", deals_ids);
    formData.append("membership_no", gstNo); //this store the gst nummber and passport number also 
    formData.append("gst", file);
    formData.append("company_address", company_address)
    formData.append("company_city", company_city)
    formData.append("company_state", company_state)
    if (payment_status !== "pay_now") {

      formData.append("payment_status", payment_status)
    }






    // formData.append("membership_no", passport_number);
    formData.append("ped", passport_expiry);
    formData.append("country_of_issue", passport_issue_country);

    formData.append("barcode", localStorage.getItem("barcode"));

    // const obj = JSON.parse(localStorage.getItem("user_data"));
    // updateOrAddKey(obj, "category", category.value);
    // updateOrAddKey(obj, "company", companyName);
    // updateOrAddKey(obj, "media_gst_membership_no", gstNumber);
    // updateOrAddKey(obj, "profession_id", profession.value);
    // if (photo) {
    //   updateOrAddKey(obj, "gst", true);
    // } else {
    //   updateOrAddKey(obj, "gst", false);
    // }

    // updateOrAddKey(obj, "professions", valueOfDealIn);
    // // localStorage.setItem("user_data", JSON.stringify(obj));
    axios
      .post(`https://jjs.samaksh.in/api/visitor/v2/register-visitor`, formData)
      .then((resData) => {
        console.log(resData.data, "response");
        setLoading(false)
        if (resData.data.payment_code) {
          setPayment(true)
          setPaymentText(resData.data.msg)
          // window.open(`https://jjs.samaksh.in/pay-now?order_id=${localStorage.getItem("barcode")}`);
          // setEncrypted_data(resData.data.payment_code);
        }
        else {
          // setDataShow(9);
          navigate(`/e-card/${localStorage.getItem('barcode')}`)
          setTimeout(() => {
            localStorage.removeItem('barcode');
            console.log('Barcode removed from localStorage');
          }, 1000);
        }

        // setDataShow(resData.data.visitor[0].step);
      });
    console.log({
      category,
      profession,
      deals_ids,
      companyName,
      dealsIn,
      gstNo,
      file,
    });
    console.log("step", step)
  };

  const handlePrevSteo = () => {
    setStep(step - 1);

  }

  useEffect(() => {
    console.log("hello i am here", selectedIds);
    set_deals_ids(selectedIds.map((item) => item.value));
  }, [selectedIds]);



  const handlePayTypeChange = (event) => {
    setPayment_status(event.target.value);
  };
  // /check-gst-validation/
  return (
    <div className="wrapper">
      <Modal
        show={payment}
        onHide={() => setPayment(false)}
        animation={false}
        centered
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FaCreditCard style={{ marginRight: '10px' }} />
            Secure Payment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-auto">
          {/* <h5>{paymentText}</h5> */}
          <h5 className="text-muted">
            Please review your Form before proceeding to payment.
          </h5>

          <>
            <a
              href={`https://jjs.samaksh.in/pay-now?order_id=${localStorage.getItem(
                "barcode"
              )}`}
              // target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary"
              style={{ display: 'inline-flex', alignItems: 'center' }}
            >
              <FaCreditCard style={{ marginRight: '8px' }} />
              Pay now
            </a>
          </>

        </Modal.Body>
      </Modal>
      <div className="image-holder">
        <img src={logo} alt="" />
      </div>
      <div className="form-inner">
        <form onSubmit={handleNextStep}>
          <div className="form-header">
            <h3>Registration Online for the show</h3>
          </div>
          <div className="form-header">
            <ul>
              <li className="filled">1</li>
              <li className="filled">2</li>
              <li className="filled">3</li>
              <li className="filled">4</li>

              {/* <li>5</li> */}
            </ul>
          </div>
          <h6 style={{ color: "#fff" }} className="blinking-text">If you want to be part of Pink Club, then please choose Gems & Jewellery.</h6>

          <h4 id="wizard-h-2" className="title current"> </h4>
          <section id="wizard-p-2" role="tabpanel" aria-labelledby="wizard-h-2" className="body current" aria-hidden="false">
            <div className="form-row" style={{ marginBottom: '3.4vh' }}>
              <label htmlFor="category">Category:</label>
              <div className="form-holder">
                <select name="category" className="form-control" value={category} onChange={handleCategoryChange} required>
                  {
                    category_list.map((item, idex) => (

                      <option value={item.value}>{item.label}</option>
                    )

                    )
                  }

                </select>
              </div>
            </div>
            {category === "trader" && <div className="form-row mt-4" style={{ marginBottom: '3.4vh' }}>
              <label style={{ marginBottom: '-5px' }}>GST No (Please mention the correct GST number).</label>
              <label htmlFor="gst_no">Kindly bring your visiting card when you arrive at the venue:</label>

              <div className="form-holder">
                <input name="membership_no" type="text" className="form-control" value={gstNo} onChange={handleGstNoChange} required />
              </div>

              {errorMessage && <span className='text-white'>{errorMessage}</span>}
            </div>}
            {(category === "trader" || category === "media") && <div className="form-row" style={{ marginBottom: '3.4vh' }}>
              <label htmlFor="company_name">{category === "media" ? "Agency Name" : "Company Name"}:</label>
              <div className="form-holder">
                <input name="company" type="text" className="form-control" value={companyName} onChange={handleCompanyNameChange} required
                  disabled={gst_data_present?.company}
                  style={{
                    color: gst_data_present?.company ? "#878787" : null,
                    backgroundColor: gst_data_present?.company ? "#cdcdcdc" : null
                  }}
                />
              </div>
            </div>}
            {category === "trader" && <div className="form-row mt-4" style={{ marginBottom: '3.4vh' }}>
              <label htmlFor="gst_no">Address:</label>
              <div className="form-holder">
                <input name="membership_no" type="text" className="form-control" value={company_address} onChange={handleGstNoChange} required
                  disabled={gst_data_present?.address}
                  style={{
                    color: gst_data_present?.address ? "#878787" : null,
                    backgroundColor: gst_data_present?.address ? "#cdcdcdc" : null
                  }}
                />
              </div>

            </div>}

            {category === "trader" && <div className="form-row mt-4" style={{ marginBottom: '3.4vh' }}>
              <label htmlFor="gst_no">City:</label>
              <div className="form-holder">
                <input name="membership_no" type="text" className="form-control" value={company_city} onChange={handleGstNoChange} required
                  disabled={gst_data_present?.city}
                  style={{
                    color: gst_data_present?.city ? "#878787" : null,
                    backgroundColor: gst_data_present?.city ? "#cdcdcdc" : null
                  }}
                />
              </div>

            </div>}
            {category === "trader" && <div className="form-row mt-4" style={{ marginBottom: '3.4vh' }}>
              <label htmlFor="gst_no">State:</label>
              <div className="form-holder">
                <input name="membership_no" type="text" className="form-control" value={company_state} onChange={handleGstNoChange} required
                  disabled={gst_data_present?.state}
                  style={{
                    color: gst_data_present?.state ? "#878787" : null,
                    backgroundColor: gst_data_present?.state ? "#cdcdcdc" : null
                  }}

                />
              </div>

            </div>}

            {(category === "visitor" || category === "trader") && <div className="form-row" style={{ marginBottom: '3.4vh' }}>
              <label htmlFor="profession">Profession:</label>
              <div className="form-holder">
                <select name="profession" className="form-control" value={profession} onChange={handleProfessionChange} required>
                  {
                    (category === 'visitor' ? profession_visistor_list : profession_list).map((item, index) => (
                      <option value={item.value}>{item.label}</option>

                    ))
                  }
                </select>
              </div>
            </div>}



            {/* <div className="form-row" style={{ marginBottom: '3.4vh' }}> */}
            {/* <label htmlFor="deals_in">Deals In:</label> */}
            {/* <div className="d-flex">
                <div className="col-md-12">
             */}
            {/* <MultiSelect
                          className=""
                          options={build_in_list}
                          value={selectedIds}
                          onChange={(selectedOption) => {
                            setSelectedIds(selectedOption);
                            console.log("line no 110 ", selectedOption);
                            // handleRoleSelectChange(selectedOption)
                          }}
                        /> */}
            {/* </div>
              </div> */}
            {/* </div> */}
            {category === "trader" && <div style={{ marginBottom: '3.4vh', position: 'relative', zIndex: 10 }}>

              <label >Deals In:</label>
              <MultiSelect
                className="multiselect-anilesh"
                options={build_in_list.map((item, index) => ({
                  label: item.label,
                  value: item.value,
                }))}
                value={selectedIds}
                styles={customStyles}
                required
                onChange={(selectedOption) => {
                  setSelectedIds(selectedOption);
                  console.log("line no 110 ", selectedOption);
                  // handleRoleSelectChange(selectedOption)
                }}
              />
            </div>}




            {(category === "media") &&
              <div className="form-row" style={{ marginBottom: '3.4vh' }}>
                <label>{category === "media" ? "Press Card" : "GST Certificate"}:</label>
                <div className="file-input-container">
                  <label className="file-input-label d-flex align-items-center m-0">
                    <span ><img className='clip-image' src={Clip} /></span> {/* Unicode for the clip icon */}
                    <input type="file" id="fileInput" className="file-input form-control" required={allreadyPresent ? false : true} onChange={handlePhotoChange} />
                    <span className="placeholder-text">{photoName ? photoName?.name.slice(0, 25) + "..." : `Select File`}</span> {/* Custom placeholder */}
                  </label>
                </div>
                <div className="form-holder">

                  {/* {allreadyPresent &&
                    < Base64Image base64String={allreadyPresent?.gst} />

                  } */}

                  {allreadyPresent && photoName ? (
                    // Show the image preview if both allreadyPresent and photoName are present
                    <div className="image-preview">
                      <img
                        src={URL.createObjectURL(photoName)}
                        alt="Preview"
                        style={{ width: '300px', height: 'auto', objectFit: 'contain' }}
                      />
                    </div>
                  ) : allreadyPresent ? (
                    // Show Base64 image if only allreadyPresent is true
                    <div className="image-preview">
                      <Base64Image base64String={allreadyPresent?.gst} />
                    </div>
                  ) : photoName && (
                    // Otherwise, show the preview of the uploaded image
                    <div className="image-preview">
                      <img
                        src={URL.createObjectURL(photoName)}
                        alt="Preview"
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </div>
                  )}
                  {/* <a
                    id="aphoto_str"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://jjs.samaksh.in/uploads/photos/dz7823.jpg?1726121151783"
                  >
                    View Existing
                  </a> */}
                </div>
              </div>
            }
            {category === "nri" &&
              <div className="form-row" style={{ marginBottom: '3.4vh' }}>
                <label htmlFor="company_name">Passport No.:</label>
                <div className="form-holder">
                  <input name="company" type="text" className="form-control" value={gstNo} onChange={(e) => setGstNo(e.target.value)} required />
                </div>
              </div>}
            {category === "nri" &&
              <div className="form-row" style={{ marginBottom: '3.4vh' }}>
                <label htmlFor="company_name">Expiry Date.:</label>
                <div className="form-holder">
                  <input name="company" type="date" className="form-control" value={passport_expiry} onChange={(e) => setPassport_expiry(e.target.value)}
                    min={new Date().toISOString().split("T")[0]}
                    required />
                </div>
              </div>}
            {category === "nri" &&
              <div className="form-row" style={{ marginBottom: '3.4vh' }}>
                <label htmlFor="category">Country Of Issue:</label>
                <div className="form-holder">
                  <select name="category" className="form-control" value={passport_issue_country} onChange={(e) => setPassport_issue_country(e.target.value)} required>
                    {
                      countryArray.map((item, idex) => (


                        <option value={item.value}>{item.label}</option>
                      )

                      )
                    }

                  </select>
                </div>
              </div>}
            <div className="checkbox-circle">
              <label>
                <input
                  name="term"
                  type="checkbox"
                  required
                  defaultChecked
                  onChange={(e) => setAgree(e.target.checked)}
                />
                I agree to all statements in{' '}
                <a href="term_conditions.html" target="_blank" rel="noopener noreferrer">
                  Terms &amp; Conditions
                </a>
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="form-row" style={{ zIndex: 1, position: 'relative' }} >

              <label >
                Payment Type
              </label>
              <div className="custom-radio-container">
                <input
                  type="radio"
                  id="free_pass"
                  name="custom-radio"
                  value="free_pass"
                  checked={payment_status === 'free_pass'}
                  onChange={handlePayTypeChange}

                />
                <label htmlFor="free_pass" className="custom-label">Free Pass</label>

                <input
                  type="radio"
                  id="pay_at_venue"
                  name="custom-radio"
                  value="pay_at_venue"
                  checked={payment_status === 'pay_at_venue'}
                  onChange={handlePayTypeChange}

                />

                <label htmlFor="pay_at_venue" className="custom-label">Pay At Venue</label>
                {allreadyPresent?.city.toLowerCase() === "jaipur" && <>
                  <input
                    type="radio"
                    id="pay_now"
                    name="custom-radio"
                    value="pay_now"
                    checked={payment_status === 'pay_now'}
                    onChange={handlePayTypeChange}

                  />

                  <label htmlFor="pay_now" className="custom-label">Pay Now</label>
                </>
                }
              </div>

            </div>

          </section>

          <div className="btn-box">
            <a onClick={handlePrevSteo} className="btn">Back</a>
            <button type='submit' className="btn">{loading ? <div class="spinner-border text-danger" role="status">
              <span class="visually-hidden">Loading...</span>
            </div> : 'Continue'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Page3;
