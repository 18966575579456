import React, { useEffect, useRef, useState } from "react";

import logo from "../images/logo-01.png";
import rubiesImage from "../images/many-rubies-01.png";
import logoWithRuby from "../images/logo-with-ruby.png";
import axios from "axios";
import { message } from "antd";
const VerifyOtp = ({
  setDataShow,
  dataShow,
  phoneNo,
  setPhoneNo,
  currentBuyer,
  setCurrentBuyer,
  reload,
  setReload,
  checkReload,
  setCheckReload,
}) => {
  const inputs = useRef([]);
  const [otp, setOtp] = useState("");

  //  useEffect(() => {
  //    inputs.current[0]?.focus();
  //  }, []);

  const handleInputChange = (index, e) => {
    setOtp((prev) => prev + e.target.value);
    const value = e.target.value;
    if (value && index < 3) {
      inputs.current[index + 1].focus();
    }
  };

  const handleVerify = (e) => {
    e.preventDefault();
    console.log("verify Otp", otp, phoneNo);
    axios
      .post(`${process.env.REACT_APP_API_END_POINT}/otp-varification`, {
        otp,
        barcode: localStorage.getItem("barcode"),
      })
      .then((resData) => {
        message.success(resData.data.message);

        if (resData.data.message === "OTP verified") {
          setCheckReload(!checkReload);
          setDataShow(4);
        } else if (resData.data.message === "all data aviliable") {
          console.log("varify otp", resData.data.data[0]);
          setCurrentBuyer(resData.data.data[0]);
          setDataShow(3);
        }
      })
      .catch((error) => {
        console.log("error:", error.message);
      });
  };
  useEffect(() => {
    localStorage.setItem("progress", dataShow);
  }, [dataShow]);
  return (
    <div>
      <div className="home-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="left-section">
                <div className="rubi-image">
                  <img className="destop-view" src={logo} alt="" />
                  <img
                    className="destop-view size-n1"
                    src={rubiesImage}
                    alt=""
                  />

                  {/* <!-- <img className="destop-view" src="images/jjs-logo-image.png" alt=""> --> */}
                  <img className="mobile-view" src={logoWithRuby} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="right-section">
                <div className="right-top-section">
                  <form id="nt-1">
                    <div className="content">
                      <h2>Enter your OTP</h2>
                      <div className="otp-input-box">
                        {[0, 1, 2, 3].map((index) => (
                          <input
                            key={index}
                            type="text"
                            name="otp"
                            className="otp-input"
                            maxLength="1"
                            required
                            ref={(el) => (inputs.current[index] = el)}
                            onChange={(e) => handleInputChange(index, e)}
                          />
                        ))}
                      </div>
                      {/* <div className="otp-input-box">
                            <input
                              type="text"
                              id="otpInput"
                              name="otp"
                              className="otp-input"
                              maxlength="1"
                              required
                            />
                            <input
                              type="text"
                              id="otpInput"
                              name="otp"
                              className="otp-input"
                              maxlength="1"
                              required
                            />
                            <input
                              type="text"
                              id="otpInput"
                              name="otp"
                              className="otp-input"
                              maxlength="1"
                              required
                            />
                            <input
                              type="text"
                              id="otpInput"
                              name="otp"
                              className="otp-input"
                              maxlength="1"
                              required
                            />
                          </div> */}
                    </div>
                    <button className="btn btn-primary" onClick={handleVerify}>
                      <a>Verify OTP</a>
                    </button>
                  </form>
                  <div className="progress">
                    <div
                      className="progress-bar "
                      role="progressbar"
                      aria-label="Default striped example"
                      style={{
                        width: " 33.32%",
                        ariaValuenow: "10",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
