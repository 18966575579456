import React, { useEffect, useState } from "react";
import logo from "../images/logo-01.png";
import rubiesImage from "../images/many-rubies-01.png";
import logoWithRuby from "../images/logo-with-ruby.png";
import axios from "axios";
import { message } from "antd";

const MissionDetails = ({
  setDataShow,
  dataShow,
  phoneNo,
  setPhoneNo,
  currentBuyer,
  reload,
  setReload,
  setCurrentBuyer,
}) => {
  const [gender, setGender] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [aadharFront, setAadharFront] = useState(null);
  const [aadharBack, setAadharBack] = useState(null);
  const [yourPhoto, setYourPhoto] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [yearMessage, setYearMessage] = useState("");
  const [city, setCity] = useState("");
  const [user, setUser] = useState("");
  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_END_POINT}/get-detail-buyer`, "", {
        headers: {
          barcode: localStorage.getItem("barcode"),
        },
      })
      .then((resData) => {
        // localStorage.setItem("barcode", resData.data.data.barcode);
        console.log("hello", resData.data.data);
     setName(resData.data.data.visitor_name);
     setEmail(resData.data.data.email);
     setCity(resData.data.data.city);
     setAadharFront(resData.data.data.id_proof_front);
     setAadharBack(resData.data.data.id_proof_back);
     setYourPhoto(resData.data.data.photo);
        setUser(resData.data.data);
      })
      .catch((error) => {
        console.log("error:", error.message);
      });
  }, []);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setErrorMessage("Please enter a valid email address");
    } else {
      setErrorMessage("");
    }
  };

  const handleAadharFrontChange = (e) => {
    setAadharFront(e.target.files[0]);
  };

  const handleAadharBackChange = (e) => {
    setAadharBack(e.target.files[0]);
  };

  const handleYourPhotoChange = (e) => {
    setYourPhoto(e.target.files[0]);
  };

  const handleContinue = (e) => {
    console.log("Gender:", city);
    console.log("Birth Year:", name);
    console.log("Email:", email);
    console.log("Aadhar Front:", aadharFront);
    console.log("Aadhar Back:", aadharBack);
    console.log("Your Photo:", yourPhoto);
    e.preventDefault();
    if (!city || !name || !email || !aadharFront || !aadharBack || !yourPhoto) {
      setErrorMessage("Please fill in all fields.");
      return;
    }
    const formData = new FormData();
    // formData.append("gender", gender);
    formData.append("mobile_number", phoneNo);
    formData.append("city", city);
    formData.append("visitor_name", name);
    formData.append("email", email);
    formData.append("id_proof_front", aadharFront);
    formData.append("id_proof_back", aadharBack);
    formData.append("photo", yourPhoto);
    // formData.append("barcode", barCode)

    axios
      .post(
        `${process.env.REACT_APP_API_END_POINT}/edit-data-buyer`,
        formData,
        {
          headers: {
            barcode: localStorage.getItem("barcode"),
          },
        }
      )
      .then((resData) => {
        message.success(resData.data.message);

        if (resData.data.message === "Otp Send") {
          setDataShow(dataShow + 1);
        } else {
          return;
        }
      })
      .catch((error) => {
        console.log("error:", error.message);
      });

    setDataShow(dataShow + 1);
    setReload(!reload);
  };

  useEffect(() => {
    localStorage.setItem("progress", dataShow);
  }, [dataShow]);

  const handleNameChange = (e) => {
    const value = e.target.value;

    // Check if the value contains any numeric characters
    if (/\d/.test(value)) {
      setErrorMessage("Name cannot contain numeric characters");
    } else {
      setName(value);
      setName(value);
      setErrorMessage("");
    }
  };
  return (
    <div>
      <div class="home-page">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="left-section">
                <div class="rubi-image">
                  <img class="destop-view" src={logo} alt="" />
                  <img class="destop-view size-n1" src={rubiesImage} alt="" />
                  <img class="mobile-view" src={logoWithRuby} alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="right-section">
                <div class="right-top-section">
                  {/* <div class="radio-btn">
                    <span>Gender:-</span>
                    <input
                      type="radio"
                      id="option1"
                      name="options"
                      value="Male"
                      checked={gender === "Male"}
                      onChange={handleGenderChange}
                    />
                    <label for="option1">Male</label>
                    <br />
                    <input
                      type="radio"
                      id="option2"
                      name="options"
                      value="Female"
                      checked={gender === "Female"}
                      onChange={handleGenderChange}
                    />
                    <label for="option2"> Female</label>
                    <br />
                  </div> */}
                  <form>
                    <div class="mb-3 destop-view">
                      <label for="exampleInputname" class="form-label">
                        Name
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputName"
                        aria-describedby="name"
                        value={name}
                        onChange={handleNameChange}
                        required
                      />
                    </div>
                    {yearMessage && (
                      <div style={{ color: "red" }}>{yearMessage}</div>
                    )}
                    <div class="mb-3 destop-view">
                      <label for="exampleInputPassword1" class="form-label">
                        email address
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        id="exampleInputPassword1"
                        value={email}
                        onChange={handleEmailChange}
                        required
                      />
                    </div>
                    <div class="mb-3 destop-view">
                      <label for="exampleInputPassword1" class="form-label">
                        City
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputPassword1"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required
                      />
                    </div>
                    <div class="mb-3 mobile-view">
                      <input
                        type="natexttextme"
                        class="form-control"
                        id="exampleInputName"
                        aria-describedby="datetime"
                        placeholder="enter your name"
                        value={name}
                        onChange={handleNameChange}
                        required
                      />
                    </div>
                    <div class="mb-3 mobile-view">
                      <input
                        type="email"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="enter your mail"
                        value={email}
                        onChange={handleEmailChange}
                        required
                      />
                    </div>
                    <div class="mb-3 mobile-view">
                      {/* <label for="exampleInputPassword1" class="form-label">
                        City
                      </label> */}
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="enter your city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required
                      />
                    </div>
                    <div
                      action="/upload"
                      method="post"
                      enctype="multipart/form-data"
                    >
                      <label>
                        upload Aadhar Front
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="file"
                        name="fileUpload"
                        id="fileUpload"
                        onChange={handleAadharFrontChange}
                        required
                      />
                    </div>
                    <div
                      action="/upload"
                      method="post"
                      enctype="multipart/form-data"
                    >
                      <label>
                        upload Aadhar Back
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="file"
                        name="fileUpload"
                        id="fileUpload"
                        onChange={handleAadharBackChange}
                        required
                      />
                    </div>
                    <div
                      action="/upload"
                      method="post"
                      enctype="multipart/form-data"
                    >
                      <label>
                        upload Your Photo
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="file"
                        name="fileUpload"
                        id="fileUpload"
                        onChange={handleYourPhotoChange}
                      />
                    </div>
                    {errorMessage && (
                      <div style={{ color: "red" }}>{errorMessage}</div>
                    )}
                    <div class="two-btn">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          // setDataShow(dataShow - 1);
                        }}
                        class="btn btn-primary btn-primary-01 "
                      >
                        <a>
                          Back
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                            />
                          </svg>
                        </a>
                      </button>
                      <button
                        onClick={handleContinue}
                        class="btn btn-primary btn-primary-01"
                      >
                        <a>
                          Continue
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                            />
                          </svg>
                        </a>
                      </button>
                    </div>
                  </form>

                  <div class="progress">
                    <div
                      class="progress-bar "
                      role="progressbar"
                      aria-label="Default striped example"
                      style={{
                        width: "49.98%",
                        ariaValuenow: "10",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionDetails;
